<template>
  <div class="d-flex justify-center align-center flex-column full-height">
    <img src="/assets/illustrations/success.svg" alt="success" class="max-100" />
    <p class="pt-8 mb-0 success-title text-center">
      {{ $t("media_files_campaign_submitted_successfully") }}
    </p>
    <p class="pt-4 mb-0 success-subtitle text-center">
      {{ $t("thanks_for_submitting_media_files_campaign") }}
    </p>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";

export default class UploadSuccess extends Vue {}
</script>

<style lang="sass" scoped>
.full-height
    height: calc(100vh - 60px)

.success-title
    font-size: 20px
    font-weight: 500
    line-height: 24px
    color: $dark-blue-color

.success-subtitle
    font-size: 14px
    font-weight: normal
    line-height: 20px
    color: $med-grey-color
.max-100
    max-width: 100%
</style>
